/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import 'animate.css';
@import 'styles/index';

@tailwind base;
@tailwind components;
@tailwind utilities;

input {
}

::-webkit-scrollbar {
  background-color: var(--white-100);
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--white-100);
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 2px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

html, body {
  height: 100%;
  overflow: hidden;
  touch-action: manipulation;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', serif;
  //font-style: normal;
}

.read-more {
  cursor: pointer;
}

.description p {
  margin: 14px 0;
}

.whitespace-unset {
  white-space: unset !important;
}


.splitter {
  &-vertical {
    width: 1px;
    height: 100%;
    background: var(--makktina-2-200);
  }

  &-horizontal {
    width: 100%;
    height: 1px;
    background: var(--makktina-2-200);
  }
}

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  transition: 0.3s;
}

.custom-modal, .custom-modal-ui {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }
}

.custom-modal-dialog {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }
}


.submenu-overlay-container {
  bottom: unset !important;
  top: 72px !important;
}

.article-overlay-container {
  bottom: unset !important;
  top: 290px !important;
}

.section {
  &-container {
    padding: 120px 240px;
    position: relative;
  }
}

button {
  outline: none;
}

.cell-wrap {
  max-width: 400px;
  min-width: 400px;
  white-space: pre-wrap!important;
}

.mat-mdc-menu-panel {
  background: transparent;
  box-shadow: none !important;
  overflow: visible !important;

  &.mat-mdc-menu-panel {
    max-width: 100% !important;
  }
}

.mdc-list-item__primary-text {
  width: 100%;
}


//.options-container {
//  display: flex;
//  flex-direction: column;
//  align-items: flex-start;
//  align-self: stretch;
//
//  .cursor-move {
//    cursor: pointer;
//
//    ::ng-deep {
//      .ui-button {
//        padding: 6px;
//      }
//    }
//  }
//
//  .options-list {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;
//    gap: 4px;
//    align-self: stretch;
//    width: 100%;
//    .options-item {
//      width: 100%;
//      display: flex;
//      align-items: center;
//      gap: 8px;
//      flex-wrap: nowrap;
//
//      .mat-mdc-form-field {
//        flex: 1;
//      }
//    }
//  }
//}

.filter-indicator {
  width: 10px;
  height: 10px;
  background: #FF008A;
  box-shadow: 0 0 0 2px #FFFFFF;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

ui-breadcrumbs.local {
  display: none;
}

.table-hide {
  display: none!important;
}

@media screen and (max-width: 1440px){
  ui-breadcrumbs.local {
    display: block;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #8F8F8E;
  opacity: .75;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;


  @media screen and (max-width: 450px){
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .custom-modal-dialog {
    height: 90vh!important;
    max-width: 90vw!important;
  }

  .custom-modal-ui {
    max-width: 90vw!important;
  }

  .fixed-content {
    overflow-x: clip;
    overflow-y: auto;
    position: fixed;
    padding: 24px;
    top: 62px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 400px) {
  .custom-popup-class {
    left: 0!important;
    right: 0!important;
  }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]~.mdc-checkbox__background {
  background-color: var(--black-50)!important;
}

.dialog-centered {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 12px;
  }
}
.dialog-br-16 {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 16px;
  }
}

.dialog-overlay {
  .mat-mdc-form-field {
    .mdc-text-field--outlined .mdc-notched-outline__leading,
    .mdc-text-field--outlined .mdc-notched-outline__trailing {
      border-color: white;
    }

    .mat-mdc-form-field-input-controll {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .mdc-text-field--outlined {
      height: 34px;
    }
  }
  div.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, div.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, div.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: white!important;
  }
  div.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
    box-shadow: none;
  }

  .mat-mdc-checkbox .mdc-form-field label {
    font-size: 12px;
    line-height: 16px;

.custom-modal-dialog {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }
}
  }
}
