.mat-mdc-form-field {
  line-height: unset;

  .mat-mdc-form-field-hint-spacer {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    &:before {
      content: none;
    }
  }
}

div.mdc-text-field--outlined {
  padding-left: 13px;
  padding-right: 13px;
  background-color: var(--white-100);
  border-radius: 6px;
  box-sizing: border-box;
  height: 38px;
  display: flex;
  align-items: center;

  .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &.mdc-text-field--disabled {
    background: var(--black-50);
  }

  &:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border-color: var(--black-300);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    }

    &:not(.mdc-text-field--focused):hover .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: var(--makktina-1-500);
      }
    }

    &.mdc-text-field--focused {
      box-shadow: 0 0 0 2px #FDE4F3, 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: var(--makktina-1-500);
      }
    }
  }
}

.custom-textarea {
  div.mdc-text-field--outlined {
    height: unset;
  }
}
.textarea-without-border {
  div.mdc-text-field--outlined {
    height: unset;
    padding: 0;
    box-shadow: none!important;
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border: none!important;
    border-radius: 0;
    box-shadow: none!important;
  }
}

.mat-mdc-form-field.textarea {
  div.mdc-text-field--outlined {
    height: auto;
  }
}

textarea.mat-mdc-form-field-textarea-control {
  letter-spacing: normal !important;

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-400);
  }
}

div.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  .mat-mdc-form-field-icon-suffix {
    ui-icon .icon {
      background: var(--error-500);
    }
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-color: var(--error-400);
  }
}

div.mdc-text-field--focused {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-width: 1px;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  margin: auto 0;
  padding: 9px 0;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 20px;
  height: 20px;
  padding: 0;
}

div.mat-mdc-form-field-infix {
  min-height: unset;
  width: unset;
}

.mat-mdc-form-field-bottom-align {
  //max-height: 16px;

  &::before {
    height: 12px !important;
  }
}

div.mat-mdc-form-field-hint-wrapper,
div.mat-mdc-form-field-error-wrapper {
  position: relative;
  padding: 0;
}

div.mat-mdc-form-field-icon-prefix {
  padding: 0 8px 0 13px;
}

div.mat-mdc-form-field-icon-suffix {
  padding: 0 13px 0 8px;
}

div.mat-mdc-form-field-icon-prefix,
div.mat-mdc-form-field-icon-suffix {
  ui-icon {
    .icon {
      background: var(--black-400);
    }
  }
}

.input-group {
  div.mat-mdc-form-field-text-suffix {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--blue-50);
    height: 100%;
    border-left: 1px solid var(--black-300);
    padding: 9px 13px;
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--blue-600);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-secondary {
    div.mat-mdc-form-field-text-suffix {
      background: var(--black-400);
      color: var(--white-100);
    }
  }

  &-success {
    div.mat-mdc-form-field-text-suffix {
      background: var(--success-500);
      color: var(--white-100);
    }
  }

  &-error {
    div.mat-mdc-form-field-text-suffix {
      background: var(--error-400);
      color: var(--white-100);
    }

    div.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
    div.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
      border-color: #FB7185;
    }
  }
}

.with-chip {
  div.mdc-text-field--outlined {
    padding-left: 2px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
